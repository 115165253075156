<template>
  <v-dialog persistent v-model="dialog1" max-width="500px">
    <v-card>
      <v-form v-on:submit.prevent="add_category()">
        <v-container style="padding: 30px">
          <v-row>
            <v-col cols="12">
              <h2>{{ $t("category") }}</h2>
            </v-col>
            <v-col cols="12">
              <ValidationObserver ref="observer1" v-slot="{ validate, reset }">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="category[lang].name"
                    :label="$t('name')"
                    class="purple-input"
                    required
                    :error-messages="errors"
                  />
                  <v-menu
                    ref="menu"
                    absolute
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    offset-y
                    min-width="auto"
                  >
                    <v-date-picker
                      v-model="dates"
                      multiple
                      color="primary"
                      no-title
                      scrollable
                      style="margin: 0"
                    >
                      <v-btn text color="primary" @click="menu = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn text color="primary" @click="add_dates($refs)">
                        {{ $t("confirm") }}
                      </v-btn>
                    </v-date-picker>

                    <template v-slot:activator="{ on, attrs }">
                      <div style="background: #f5f5f5">
                        <v-container
                          style="
                            margin-top: 1em;
                            padding-top: 2em;
                            padding-bottom: 2em;
                          "
                        >
                          <v-row style="display: flex; place-content: center">
                            <div
                              style="display: flex; place-content: center"
                              v-for="(item, index) in dates"
                              :key="index"
                            >
                              <v-btn
                                @click="show_date_dialog(item)"
                                style="
                                  margin: 0.5em;
                                  background: #f5f5f5;
                                  border-radius: 10em;
                                  width: 7em;
                                  font-weight: bold;
                                "
                                color="primary"
                              >
                                {{ new Date(item).getDate() }}.
                                {{ new Date(item).getMonth() + 1 }}.
                                {{ new Date(item).getFullYear() }}
                              </v-btn>
                            </div>

                            <v-btn
                              multiple
                              chips
                              small-chips
                              dark
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              style="
                                margin: 0.5em;
                                background: #f5f5f5;
                                border-radius: 10em;
                                width: 7em;
                              "
                            >
                              <v-icon dark style="">mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </div>
                    </template>
                  </v-menu>
                  <date-dialog
                    v-if="dateDialog"
                    :date="dateObject"
                    @close-dialog="close_date_dialog"
                  >
                  </date-dialog>
                </ValidationProvider>
              </ValidationObserver>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn text @click="close_dialog" color="primary" class="mr-0">{{
                $t("cancel")
              }}</v-btn>
              <v-btn @click="add_category()" color="primary" class="mr-0">{{
                $t("confirm")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    "date-dialog": () => import("./DateDialog.vue"),
  },
  props: ["dialog1", "category", "edit_mode", "lang"],
  data() {
    return {
      dateObjects: {},
      menu: false,
      dateDialog: false,
      dateObject: null,
      dat: [],
    };
  },
  computed: {
    dates: {
      get() {
        if (this.dat.length === 0) {
          for (let i in this.category.dates) {
            this.dat.push(i);
          }

          this.dateObjects = this.category.dates;
        }
        return this.dat;
      },
      set(val) {
        let exists = false;
        let objects = {};
        val.forEach((el) => {
          this.dat.forEach((dat_el) => {
            if (el == dat_el) {
              objects[el] = this.dateObjects[el];
              exists = true;
            }
          });
          if (!exists) {
            objects[el] = { id: el, repeat: "daily" };
          }
          exists = false;
        });
        this.dateObjects = objects;
        this.category.dates = objects;
        objects = {};
        this.dat = val;
      },
    },
  },
  methods: {
    show_date_dialog(date) {
      this.dateDialog = true;
      this.dateObject = this.dateObjects[date];
    },
    close_date_dialog({ selected }) {
      for (let i in this.dateObjects) {
        if (this.dateObjects[i].id === this.dateObject.id)
          this.dateObjects[i].repeat = selected.value;
      }
      this.dateDialog = false;
    },
    add_dates(refs) {
      refs.menu.save(this.dates);
    },
    close_dialog() {
      this.dat = [];
      this.dateObjects = {};
      this.dateDialog = false;
      this.$emit("close-dialog");
    },
    add_category: function () {
      this.$refs.observer1.validate().then((result) => {
        if (result) {
          this.category.parentId = this.category.parentId
            ? this.category.parentId
            : null;

          if (this.edit_mode)
            this.$store.dispatch("UPDATE_CATEGORY", {
              category: this.category,
            });
          else {
            let index = 0;
            if (this.category.parentId) {
              this.$store.state.categories.forEach((el) => {
                if (this.category.parentId === el.parentId)
                  index = el.index + 1;
              });
            } else {
              this.$store.state.categories.forEach((el) => {
                if (!el.parentId) index = el.index + 1;
              });
            }
            this.category.index = index;

            this.$store.state.languages.forEach((element) => {
              if (element.value != this.$store.state.lang) {
                this.category[element.value] = Object.assign(
                  {},
                  this.category[this.$store.state.lang]
                );
              }
            });
            this.$store.dispatch("SAVE_CATEGORY", {
              category: this.category,
            });
          }
          this.$notify({
            group: "foo",
            title: this.$t("success"),
            text: this.category_update
              ? this.$t("category_updated")
              : this.$t("category_added"),
            type: "success",
          });
          this.$refs.observer1.reset();
          this.dat = [];
          this.dateObjects = {};
          this.dateDialog = false;
          this.$emit("close-dialog");
        }
      });
    },
  },
};
</script>
